import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pistols 8-8-8/leg (weighted if possible)`}</p>
    <p>{`Glute Ham Raise 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time of:`}</p>
    <p>{`10-DB Step Ups, 24/20″ (30’s/20’s)`}</p>
    <p>{`10-GHD Situps`}</p>
    <p>{`10-Goblet Squats (53/35)`}</p>
    <p>{`10-KBS’s (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday is our free to all workout at our normal class times of
8:00 & 9:00am at The Ville and 10:00 & 11:00am at East.  Bring a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      